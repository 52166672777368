export default class Constants {
    // development
    static BASE_URL = "https://fundamentalmusic.appdeft.in/api/";
    static IMG_URL = "https://fundamentalmusic.appdeft.in/";

    //production
    // static BASE_URL = "https://app.fundamentalmusic.org/api/";
    // static IMG_URL = "https://app.fundamentalmusic.org/";

    static END_POINT = {
        //Student Routes
        LOGIN: "students/login",
        GET_SCHOOLS: "students/schools",
        VIEW_SCHOOL: "students/school-information/",
        SENT_OTP: "students/email-otp",
        VERIFY_OTP: "students/verify-otp",
        RESET_PASSWORD: "students/reset-password",
        VIEW_SCHOOL_ADMISSION_FORM: "students/school-admission-form/",
        SCHOOL_GRADES: "students/school-grades/",
        SCHOOL_PROGRAMS: "students/grade-class-types",
        CLASS_TYPE_PROGRAMS: "students/class-type-programs",
        PROGRAM_INSTRUMENTS: "students/program-intruments/",
        VIEW_INSTRUMENT: "students/instrument-view/",
        ADD_STUDENT: "students/add-student-information",
        ADD_BILLING_INFO: "students/add-billing-information",
        VIEW_ONLINE_LESSONS: "students/view-online-lesson/",
        VIEW_ONLINE_ENROLLMENT_LESSONS: "students/online-lesson-more-info/",
        VIEW_SCHOOL_LESSONS: "students/view-school-lesson/",
        GET_ONLINE_LESSONS: "students/private-online-lessons",
        ONLINE_LESSON_GRADES: "students/online-lesson-grades/",
        GET_STUDENT_PROFILE: "students/profile",
        STUDENT_DASHBOARD: "students/dashboard",
        STUDENT_NEWS: "students/news",
        STUDENT_MESSAGE_HISTORY: "students/message-history",
        STUDENT_ATTENDENCE_NOTES: "students/attendance-notes",
        GET_STUDENT_EVENTS: "students/events",
        STUDENT_PRACTICE_LOGS: "students/practice-logs",
        STUDENT_ADD_PRACTICE_LOGS: "students/add-practice-log",
        STUDENT_DELETE_PRACTICE_LOGS: "students/delete-practice-log/",
        UPLOAD_FILES: "students/file-upload",
        PROFILE_SETTINGS: "students/edit-profile",
        ALL_INSTRUMENTS: "students/instruments",
        GRADE_INSTRUMENTS: "students/grade-detail",
        GRADE_INSTRUMENTS_PROGRAMS: "students/grade-instrument-program-type",
        MORE_PROGRAM_INFO: "students/program-type-more-info/",
        SCHOOL_CLASSES: "students/school-grade-programs",

        //Teacher Routes
        TEACHER_LOGIN: "teacher/login",
        GET_TEACHER_PROFILE: "teacher/profile",
        TEACHER_SENT_OTP: "teacher/email-otp",
        TEACHER_VERIFY_OTP: "teacher/verify-otp",
        TEACHER_RESET_PASSWORD: "teacher/reset-password",
        TEACHER_DASHBOARD: "teacher/dashboard",
        TEACHER_OVERDUES: "teacher/overdue-attendance",
        TEACHERS_LIST: "teacher/teachers",
        PRIVELEGES_TEACHER: "teacher/privileges-teachers",
        TEACHER_NEWS: "teacher/news",
        TEACHER_ASSIGNMENT: "teacher/assignments",
        STUDENT_DROPDOWN: "teacher/student-dropdown",
        GET_COMPENSATION: "teacher/compensations",
        VIEW_TEACHER_ATTENDENCE: "teacher/attendances",
        GET_TEACHER_SCHOOL_DROPDOWN: "teacher/school-dropdown",
        TEACHER_CLASS_TYPES: "teacher/class-schedule/",
        TEACHER_PROFILE: "teacher/profile",
        VIEW_TEACHER_STUDENTS: "teacher/students",
        VIEW_TEACHER_STUDENTS_DETAILS: "teacher/student-detail/",
        VIEW_TEACHER_ASSIGNMENTS: "teacher/view-assignment/",
        TEACHER_FEEDBACK: "teacher/send-feedback",
        UPLOAD_TEACHER_FILES: "teacher/file-upload",
        TEACHER_PROFILE_SETTINGS: "teacher/edit-profile",
        CHANGE_TEACHER_PASSWORD: "teacher/change-password",
        GET_AVAILIBILITY: "teacher/availabilities",
        ADD_TEACHER_AVAILABILITY: "teacher/add-availability",
        SET_ALL_UNRECORDED: "teacher/set-all-attendance",
        EMAIL_DROPDOWN: "teacher/all-emails-dropdown",
        SEND_EMAIL: "teacher/send-email",
        GET_TEACHER_MESSAGE_HISTORY: "teacher/message-history",
        VIEW_SCHOOL_TEACHERS: "teacher/school-teachers/",
        TEACHER_STUDENTS: "teacher/teacher-students/",
        GET_EMAIL_TEMPLATE: "teacher/email-templates/",
        VIEW_TEMPLATE: "teacher/view-email-template/",
        ADD_EMAIL_TEMPLATE: "teacher/add-email-template",
        SEND_EMAIL_LIST: "teacher/send-email-list",
        TEACHER_VIEW_EMAIL: "teacher/view-email/",
        ADD_SCHEDULE: "teacher/add-class-schedule",
        EDIT_SCHEDULE: "teacher/edit-class-schedule",
        GET_ALL_SCHEDULES: "teacher/all-class-schedule",
        GET_SCHEDULE_EVENTS: "teacher/get-school-events/",
        GET_SCHEDULE_EVENTS_CLASSES: "teacher/school-classes",
        ADD_SCHEDULE_EVENTS_CLASSES: "teacher/add-school-event",
        GET_SCHEDULE_EVENT: "teacher/view-school-event/",
        DELETE_SCHEDULE_EVENT: "teacher/delete-school-event/",
        GET_SCHEDULE: "teacher/get-class-schedule/",
        ADD_SCHEDULE_CLASS: "teacher/add-class",
        EDIT_SCHEDULE_CLASS: "teacher/edit-class-details",
        DELETE_SCHEDULE_CLASS: "teacher/delete-class-details/",

        // Events

        VIEW_EVENT: "teacher/view-event/",
        VIEW_ATTENDENCE: "teacher/student-attendance",
        ADD_ATTENDENCE: "teacher/add-attendance",
        GET_TEACHER_EVENTS: "teacher/events",
        PARENT_PRACTICE_LOGS: "parent/practice-logs",
        EDIT_EVENT: "teacher/edit-event/",
        GET_CATEGORY: "teacher/categories",

        //Teacher

        TEACHER_LIST: "teacher/teachers",
        VIEW_TEACHER_SCHOOL: "teacher/view-school/",

        //Parent Roues

        PARENT_LOGIN: "parent/login",
        GET_PARENT_PROFILE: "parent/profile",
        CHANGE_PASSWORD: "parent/change-password",
        GET_PARENT_STUDENTS: "parent/students",
        SET_STUDENT_LOGIN: "parent/set-student-login",
        GET_INVOICES: "parent/invoices",
        PARENT_SENT_OTP: "parent/email-otp",
        PARENT_VERIFY_OTP: "parent/verify-otp",
        PARENT_RESET_PASSWORD: "parent/reset-password",
        PARENT_DASHBOARD: "parent/dashboard/",
        PARENT_CONTACT_INFO: "parent/contact-info",
        NEWS: "parent/news",
        GET_MESSAGE_HISTORY: "parent/message-history",
        GET_ASSIGNMENTS: "parent/assignments",
        GET_NOTES: "parent/attendance-notes",
        GET_ACCOUNTS: "parent/trasactions",
        VIEW_STUDENT: "parent/student-details/",
        GET_EVENTS: "parent/events",
        VIEW_PARENT_ASSIGNMENTS: "parent/view-assignment/",
        EDIT_STUDENT: "parent/edit-student/",
        PARENT_SCHOOL_DROPDOWN: "parent/school-dropdown",
        GRADE_CLASS_TYPE: "parent/grade-class-types",
        PARENT_CLASS_TYPE_PROGRAMS: "parent/class-type-programs",
        SCHOOL_PROGRAM_INSTRUMENTS: "parent/program-intruments/",
        PARENT_ADD_STUDENTS: "parent/add-student",
        SCHOOL_LESSONS: "parent/get-lessons",
        SCHOOL_INSTRUMENT: "parent/view-instrument/",
        ONLINE_INSTRUMENTS: "parent/online-lesson-instruments/",
        UPLOAD_PARENT_FILES: "parent/file-upload",
        PARENT_PROFILE_SETTINGS: "parent/edit-profile",
        CHANGE_PARENT_PASSWORD: "parent/change-password",
        GET_PARENT_CARDS: "parent/cards",
        ADD_CARD: "parent/add-card",
        DELETE_CARD: "parent/delete-card/",
        MAKE_PAYMENT: "parent/make-payment",
        VIEW_INVOICE: "parent/view-invoice",
        UNPAID_INVOICES: "parent/unpaid-invoices-details",
    };
}
