import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../layout";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { useDispatch } from "react-redux";
import {
    onAddClassEventAction,
    onDeleteEventAction,
    onGetEventAction,
    onGetEventClassesAction,
    onGetEventsAction,
} from "../../../../../redux/actions/TeacherPortal/scheduleAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Skeleton } from "primereact/skeleton";
import { Chip } from "primereact/chip";
import moment from "moment";
import { convertTo12HourFormat } from "../../../../../utils/dates";
import deleteIcon from "../../../../../assets/icons/delete.png";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import Popup from "./Popup";
export default function CalanderSchedule() {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const externalEventsRef = useRef(null);
    const op = useRef(null);

    const { id } = useParams();

    const [data, setData] = useState(null);
    const [classes, setClasses] = useState([]);
    const [hiddenDays, setHiddenDays] = useState([0, 1, 2, 3, 4, 5]);

    const [events, setEvents] = useState([]);
    const flag = useRef(true);

    useEffect(() => {
        if (data) {
            let _events = data?.class_event_raw.map((item) => getFormattedEvent(item));
            setEvents(_events);
        }
    }, [data]);

    useEffect(() => {
        if (flag.current && events.length) {
            calendarRef.current.getApi().prev();
            flag.current = false;
        }
    }, [events]);

    useEffect(() => {
        if (data) {
            let _hiddenDays = [];
            days.forEach((day, i) => {
                if (day !== data?.class_schedule?.day) {
                    _hiddenDays.push(i);
                }
            });
            setHiddenDays(_hiddenDays);
        }
    }, [data]);

    useEffect(() => {
        if (calendarRef?.current && events?.length) {
            setTimeout(() => {
                calendarRef.current.getApi().gotoDate(data?.class_schedule?.start_date);
            }, 10);
        }
    }, [data?.class_schedule?.start_date]);

    useEffect(() => {
        new Draggable(externalEventsRef.current, {
            itemSelector: ".fc-event-drag",
            eventData: function (eventEl) {
                return {
                    title: eventEl.getAttribute("data-title"),
                    duration: eventEl.getAttribute("data-duration"),
                    extraInfo: eventEl.getAttribute("data-extra-info"),
                };
            },
        });
    }, []);

    useEffect(() => {
        dispatch(
            onGetEventsAction(id, (e) => {
                setData(e);
            })
        );
        dispatch(
            onGetEventClassesAction(id, (e) => {
                setClasses(e);
            })
        );
    }, []);
    const getFormattedEvent = (item) => {
        let duration = item?.duration || 30;
        let start = `${item?.date}T${item.time}:00`;
        let end = moment(start);
        end = end.add(duration, "minutes").format("YYYY-MM-DDTHH:mm:ss");

        let obj = {
            ...item,
            title: item?.class_title,
            start,
            end,
        };

        return obj;
    };
    const handleEventDrop = (info) => {
        const scrollableEl = calendarRef.current.getApi().el.querySelector(".fc-scroller-liquid-absolute");
        const scrollPosition = scrollableEl ? scrollableEl.scrollTop : 0;

        const event = info.event;

        const droppedDate = event.start;
        const date = moment(droppedDate).format("YYYY-MM-DD");
        const time = moment(droppedDate).format("HH:mm");

        const eventData = info.draggedEl;
        let extraInfo = eventData.getAttribute("data-extra-info");
        extraInfo = JSON.parse(extraInfo);

        let data = {
            class_id: extraInfo?.id,
            date,
            time,
        };
        dispatch(
            onAddClassEventAction(data, (res) => {
                let obj = getFormattedEvent(res);
                setEvents((prev) => [...prev, obj]);
                event.remove();
                setTimeout(() => {
                    if (scrollableEl) {
                        scrollableEl.scrollTop = scrollPosition;
                    }
                }, 0);
            })
        );
    };

    const handleEventChange = (info) => {
        const scrollableEl = calendarRef.current.getApi().el.querySelector(".fc-scroller-liquid-absolute");
        const scrollPosition = scrollableEl ? scrollableEl.scrollTop : 0;

        const event = info.event;
        const droppedDate = event.start;
        const date = moment(droppedDate).format("YYYY-MM-DD");
        const time = moment(droppedDate).format("HH:mm");
        const extraInfo = info.event.extendedProps;

        let data = {
            event_id: extraInfo?.event_id,
            class_id: extraInfo?.class_schedule_list_id,
            date,
            time,
        };

        dispatch(
            onAddClassEventAction(data, (res) => {
                console.log(res);
                let _events = [...events];
                let index = _events.findIndex((item) => item.event_id === extraInfo?.event_id);
                if (index >= 0) {
                    _events[index] = getFormattedEvent(res);
                    setEvents(_events);
                    setTimeout(() => {
                        if (scrollableEl) {
                            scrollableEl.scrollTop = scrollPosition;
                        }
                    }, 0);
                }
            })
        );
    };

    const [calendarHeight, setCalendarHeight] = useState(window.innerHeight - 200);

    const handleEventClick = (clickInfo) => {
        const extraInfo = clickInfo.event.extendedProps;

        const el = document.getElementById("primeMenu");
        if (el) {
            el.style.display = "none";
        }
        clickInfo.el.addEventListener("click", (event) => {
            op?.current?.show(event);
        });
        clickInfo.el.click();
        op.current.toggle("");
        dispatch(onGetEventAction(extraInfo?.event_id));
    };

    return (
        <Layout>
            <div className="px-5 py-3">
                <div className="flex justify-content-between">
                    <div className=" flex text-main text-2xl open-sans font-semibold my-auto">
                        {data?.class_schedule?.school_name} {data?.class_schedule?.day && `(${data?.class_schedule?.day})`}
                    </div>

                    <span className="p-buttonset">
                        <Button icon="pi pi-angle-left" size="small" onClick={() => calendarRef.current.getApi().prev()} />
                        <Button icon="pi pi-angle-right" size="small" onClick={() => calendarRef.current.getApi().next()} />
                    </span>
                </div>

                <div id="external-events" className="flex gap-2 my-2 overflow-y-auto" ref={externalEventsRef}>
                    {classes.map((event, index) => (
                        <div
                            key={index}
                            className="fc-event-drag cursor-pointer"
                            data-title={event.class_title}
                            data-duration={`00:${event?.duration}:00`}
                            data-extra-info={JSON.stringify(event)}
                        >
                            <Chip className="px-5" label={event.class_title} />
                        </div>
                    ))}
                </div>

                <FullCalendar
                    height={calendarHeight}
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={false}
                    initialView="customGridView"
                    editable={true}
                    droppable={true}
                    allDaySlot={false}
                    hiddenDays={hiddenDays}
                    slotDuration="00:05:00"
                    dayHeaderFormat={{ month: "short", day: "numeric" }}
                    views={{
                        customGridView: {
                            type: "timeGrid",
                            duration: { days: 60 },
                        },
                    }}
                    validRange={{
                        start: data?.class_schedule?.start_date,
                        end: data?.class_schedule?.end_date,
                    }}
                    slotMinTime={data?.class_schedule?.start_time ? data?.class_schedule?.start_time + ":00" : "09:00:00"}
                    slotMaxTime={data?.class_schedule?.end_time ? data?.class_schedule?.end_time + ":00" : "16:00:00"}
                    eventDurationEditable={false}
                    eventChange={handleEventChange}
                    eventReceive={handleEventDrop}
                    eventClick={handleEventClick}
                    events={events}
                />
            </div>
            <Popup calendarRef={calendarRef} op={op} events={events} setEvents={setEvents} />
        </Layout>
    );
}
